.App {
  text-align: center;
  background: #A2E5E8;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
}

.CircleWrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.CircleLeft {
  fill: #ff4b4b;
  border-radius: 50%;
  position: absolute;
  transform: scale(0.5);
  animation: move 15s ease-in-out infinite;
  transform-origin: 50% 50%;
  width: 20vmax;
}

.CircleRight {
  /*height: 50vw;*/
  /*width: 50vw;*/
  /*background: #B3E9EC;*/
  /*border-radius: 50%;*/
  /*position: relative;*/
  /*right: -65vw;*/
  /*bottom: -20vw;*/
  /*overflow-y: hidden;*/

  z-index: 8;
  position: absolute;
  right: 0;
  left: 0;
  fill: #023F92;
  width: 50vmax;
  animation: move 10s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes move {
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
}

.NameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NameWrapper > span {
  font-family: FredokaOne;
  color: #FFF;
  text-align: left;
  font-size: 4em;
}

.ContentWrapper {
  z-index: 30;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.SocialMediaWrapper {
  margin-top: 16px;
  display: flex;
  align-self: center;
  align-content: flex-start;
}

.SocialMediaWrapper > div {
  align-items: center;
  align-content: center;
  margin: 21px;
  display: flex;
  flex-direction: column;
}

.SocialMediaWrapper > div:hover {
  opacity: 0.8;
  cursor: pointer;
}

.SocialMediaWrapper > div > .Name {
  color: #FFF;
  font-family: Roboto Black;
  margin-top: 4px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'FredokaOne';
  font-style: normal;
  font-weight: normal;
  src: local('FredokaOne'), url('./fonts/FredokaOne-Regular.ttf') format("truetype");
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
